<template>
  <div id="job_news">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="uilchilgeenii_nuhtsul_title">
            <span class="yelow">{{'hadgalsan' | translate}}</span>{{'zahialgat_ajil' | translate}}
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12">
            <div class="job_news_tabs">
              <Tabs type="card">
                <TabPane :label="'baiguullaga' | translate">
                  <div v-if="loading" class="loading-wrap">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="p_table">
                      <table width="100%" v-if="ds_save_zar_baiguullaga_ajil_zar.length > 0">
                        <tbody>
                        <tr  :key="index" v-for="(baiguullaga_zar, index) in ds_save_zar_baiguullaga_ajil_zar">
                          <td>
                            <div class="col_1 ">
                              <router-link :to="`/b_a_z_details/${baiguullaga_zar.zar_id}`">
                                <h5 v-html="baiguullaga_zar.guitsetgeh_ajil"></h5>
                              </router-link>
                              <div class="flex_col">
                                <div class="sub_col_2">
                                  <span class="company_n">{{ baiguullaga_zar.baiguullaga_ner}}</span>
                                  <div class="text_bold">{{ 'where' | translate }}:
                                    <span class="not_bold"> {{ baiguullaga_zar.aimagname }}</span>
                                  </div>
                                </div>
                                <div class="static_button ">
                                  {{ baiguullaga_zar.tsagiin_torol }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="javascript:void(0);"  @click="deleteBaiguullagaData(baiguullaga_zar.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
                            <div class="td_3">
                              <div class="td_3_1" style="padding-right: 10px">
                                <p>{{ 'publication' | translate }} <br> {{ 'p_date' | translate }}</p>
                                <p class="diff_p_text">{{ baiguullaga_zar.niitelsen_ognoo | date}}</p>
                              </div>
                              <div class="td_3_2">
                                <p>{{ 'Expiry' | translate }} <br> {{ 'date' | translate }}</p>
                                <p class="p_text ">{{ baiguullaga_zar.sanal_avch_d_ognoo | date}}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div v-else class="no-data">
                          {{ 'no_saved_ads' | translate}}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane :label="'brigad' | translate">
                  <div v-if="loading" class="loading-wrap">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="p_table">
                      <table width="100%" v-if="ds_save_zar_brigad_ajil_zar.length > 0">
                        <tbody>
                        <tr  :key="index1" v-for="(briga_zar, index1) in ds_save_zar_brigad_ajil_zar">
                          <td>
                            <div class="col_1 ">
                              <router-link :to="`/br_a_z_details/${briga_zar.zar_id}`">
                                <h5>{{ briga_zar.ajiliin_bair }}</h5>
                              </router-link>
                              <div class="flex_col">
                                <div class="sub_col_2">
                                  <span class="company_n">{{ briga_zar.brigad_ner}}</span>
                                  <div class="text_bold">{{ 'where' | translate }}:
                                    <span class="not_bold"> {{ briga_zar.aimagname }}</span>
                                  </div>
                                </div>
                                <div class="static_button ">
                                  {{ briga_zar.tsagiin_torol }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="javascript:void(0);"  @click="deleteBRIGAD_AJIL_ZAR(briga_zar.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
                            <div class="td_3">
                              <div class="td_3_1" style="padding-right: 10px">
                                <p>{{ 'publication' | translate }} <br> {{ 'p_date' | translate }}</p>
                                <p class="diff_p_text">{{ briga_zar.niitelsen_ognoo | date}}</p>
                              </div>
                              <div class="td_3_2">
                                <p>{{ 'Expiry' | translate }} <br> {{ 'date' | translate }}</p>
                                <p class="p_text ">{{ briga_zar.duusah_ognoo | date}}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div v-else class="no-data">
                          {{ 'no_saved_ads' | translate}}
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>


import {
  GET_SAVE_BAIGUULLAGA_AJIL_ZAR,
  GET_SAVE_BRIGAD_AJIL_ZAR,
  POST_DELETE_BAIGUULAGA_AJIL_ZAR,
  POST_DELETE_BRIGAD_AJIL_ZAR,
} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
  name: "savedItems",
  data() {
    return {
      loading: true,
      ds_save_zar_baiguullaga_ajil_bair_zar: [],
      ds_save_zar_brigad_ajil_bair_zar: [],
      ds_save_zar_baiguullaga_ajil_zar: [],
      ds_save_zar_brigad_ajil_zar: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  methods: {
    getGET_SAVE_BAIGUULLAGA_AJIL_ZAR() {
      this.loading = true;
      this.$apollo.query({query: GET_SAVE_BAIGUULLAGA_AJIL_ZAR, fetchPolicy: 'no-cache', variables: {user_id: this.user.id.toString()}}).then(({data}) => {
        this.ds_save_zar_baiguullaga_ajil_zar = data.ds_save_zar_baiguullaga_ajil_zar;
        this.loading = false;
      })
    },
    getGET_SAVE_BRIGAD_AJIL_ZAR() {
      this.loading = true;
      this.$apollo.query({query: GET_SAVE_BRIGAD_AJIL_ZAR, fetchPolicy: 'no-cache', variables: {user_id: this.user.id.toString()}}).then(({data}) => {
        this.ds_save_zar_brigad_ajil_zar = data.ds_save_zar_brigad_ajil_zar;
        this.loading = false;
      })
    },
    deleteBaiguullagaData(id){
      this.$apollo.mutate({mutation: POST_DELETE_BAIGUULAGA_AJIL_ZAR,
        variables: {
          id: id,
        }
      }).then(() =>{
        this.$Notice.success({title: this.$t("successfully_Deleted"),});
        // this.ds_save_zar_baiguullaga_ajil_bair_zar = this.ds_save_zar_baiguullaga_ajil_bair_zar.find(item=>item.id != id);
        let deleteIndex = this.ds_save_zar_baiguullaga_ajil_zar.findIndex(item=>item.id == id);
        if(deleteIndex>=0){
          this.ds_save_zar_baiguullaga_ajil_zar.splice(deleteIndex, 1);
          this.$store.commit("removeBaiguullagaAjilZar",  id);
        }
      });
    },
    deleteBRIGAD_AJIL_ZAR(id){
      this.$apollo.mutate({mutation: POST_DELETE_BRIGAD_AJIL_ZAR,
        variables: {
          id: id,
        }
      }).then(() =>{
        this.$Notice.success({title: this.$t("successfully_Deleted"),});
        let deleteIndex = this.ds_save_zar_brigad_ajil_zar.findIndex(item=>item.id == id);
        if(deleteIndex>=0){
          this.ds_save_zar_brigad_ajil_zar.splice(deleteIndex, 1);
          this.$store.commit("removeBrigadAjilZar",  id);
        }
      });
    }
  },
  mounted() {
    this.getGET_SAVE_BAIGUULLAGA_AJIL_ZAR();
    this.getGET_SAVE_BRIGAD_AJIL_ZAR();
  }

}
</script>

<style scoped>

</style>
