<template>
    <div id="job_news">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="uilchilgeenii_nuhtsul_title">
                        <span class="yelow">{{'hadgalsan' | translate}}</span>{{'ajliin_bairnii_zar' | translate}}
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="job_news_tabs">
                  <Tabs type="card">
                    <TabPane :label="'baiguullaga' | translate">
                      <div v-if="loading" class="loading-wrap">
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="p_table">
                          <table width="100%" v-if="ds_save_zar_baiguullaga_ajil_bair_zar.length > 0">
                            <tbody>
                            <tr :key="zindex" v-for="(zar, zindex) in ds_save_zar_baiguullaga_ajil_bair_zar">
                              <td>
                                <div class="col_1 ">
                                  <router-link :to="`/b_a_b_zar_details/${zar.zar_id}`">
                                    <h5>{{ zar.ajiliin_bair_ner }}</h5>
                                  </router-link>
                                  <div class="flex_col">
                                    <div class="sub_col_2">
                                      <span class="company_n">{{ zar.baiguullaga_ner}}</span>
                                      <div class="text_bold">{{ 'where' | translate }}:
                                        <span class="not_bold"> {{ zar.aimagname }}</span>
                                      </div>
                                    </div>
                                    <div class="static_button ">
                                      {{ zar.tsagiin_torol }}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a href="javascript:void(0);"  @click="deleteBaiguullagaData(zar.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
                                <div class="td_3">
                                  <div class="td_3_1" style="padding-right: 10px">
                                    <p>{{ 'Date_of_publication' | translate }}</p>
                                    <p class="diff_p_text">{{ zar.niitelsen_ognoo | date}}</p>
                                  </div>
                                  <div class="td_3_2">
                                    <p>{{ 'Expiry_date' | translate }}</p>
                                    <p class="p_text ">{{ zar.duusah_ognoo | date}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>


                            </tbody>
                          </table>
                          <div v-else class="no-data">
                              {{ 'no_saved_ads' | translate}}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane :label="'brigad' | translate">
                      <div v-if="loading" class="loading-wrap">
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="p_table">
                          <table width="100%" v-if="ds_save_zar_brigad_ajil_bair_zar.length > 0">
                            <tbody>
                            <tr :key="index1" v-for="(brigad_zar, index1) in ds_save_zar_brigad_ajil_bair_zar">
                              <td>
                                <div class="col_1 ">
                                  <router-link :to="`/br_a_b_zar_details/${brigad_zar.zar_id}`">
                                    <h5>{{ brigad_zar.ajiliin_bair }}</h5>
                                  </router-link>
                                  <div class="flex_col">
                                    <div class="sub_col_2">
                                      <span class="company_n">{{ brigad_zar.brigad_ner}}</span>
                                      <div class="text_bold">{{ 'where' | translate }}:
                                        <span class="not_bold"> {{ brigad_zar.aimagname }}</span>
                                      </div>
                                    </div>
                                    <div class="static_button ">
                                      {{ brigad_zar.tsagiin_torol }}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a href="javascript:void(0);"  @click="deleteBrigadData(brigad_zar.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
                                <div class="td_3">
                                  <div class="td_3_1" style="padding-right: 10px">
                                    <p>{{ 'publication' | translate }} <br> {{ 'p_date' | translate }}</p>
                                    <p class="diff_p_text">{{ brigad_zar.niitelsen_ognoo | date}}</p>
                                  </div>
                                  <div class="td_3_2">
                                    <p>{{ 'Expiry' | translate }} <br> {{ 'date' | translate }}</p>
                                    <p class="p_text ">{{ brigad_zar.duusah_ognoo | date}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div v-else class="no-data">
                              {{ 'no_saved_ads' | translate}}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>


import {
  GET_SAVE_BAIGUULLAGA_AJIL_BAIR_ZAR,
  GET_SAVE_BRIGAD_AJIL_BAIR_ZAR,
  POST_DELETE_BAIGUULAGA_AJIL_BAIR_ZAR,
  POST_DELETE_BRIGAD_AJIL_BAIR_ZAR,
} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
    name: "savedItems",
    data() {
        return {
            loading: true,
            ds_save_zar_baiguullaga_ajil_bair_zar: [],
            ds_save_zar_brigad_ajil_bair_zar: [],
            ds_save_zar_baiguullaga_ajil_zar: [],
            ds_save_zar_brigad_ajil_zar: [],
        }
    },
    computed: {
      ...mapGetters([
         'user',
        // ...
      ]),
    },
    methods: {

      getGET_SAVE_BAIGUULLAGA_AJIL_BAIR_ZAR() {
        this.user_id = this.user.id
        this.loading = true;
        this.$apollo.query({query: GET_SAVE_BAIGUULLAGA_AJIL_BAIR_ZAR,  fetchPolicy: 'no-cache', variables: {user_id: this.user_id.toString()}}).then(({data}) => {
          this.ds_save_zar_baiguullaga_ajil_bair_zar = data.ds_save_zar_baiguullaga_ajil_bair_zar;
          //console.log(this.ds_save_zar_baiguullaga_ajil_bair_zar);
          this.loading = false;
        })
      },
      getGET_SAVE_BRIGAD_AJIL_BAIR_ZAR() {
        this.user_id = this.user.id
        this.loading = true;
        this.$apollo.query({query: GET_SAVE_BRIGAD_AJIL_BAIR_ZAR, fetchPolicy: 'no-cache', variables: {user_id: this.user_id.toString()}}).then(({data}) => {
          this.ds_save_zar_brigad_ajil_bair_zar = data.ds_save_zar_brigad_ajil_bair_zar;
          this.loading = false;
        })
      },
      deleteBaiguullagaData(id){
        this.$apollo.mutate({mutation: POST_DELETE_BAIGUULAGA_AJIL_BAIR_ZAR,
          variables: {
            id: id,
        }
        }).then(() =>{
          this.$Notice.success({title: this.$t("successfully_Deleted"),});
          // this.ds_save_zar_baiguullaga_ajil_bair_zar = this.ds_save_zar_baiguullaga_ajil_bair_zar.find(item=>item.id != id);
          let deleteIndex = this.ds_save_zar_baiguullaga_ajil_bair_zar.findIndex(item=>item.id == id);

          if(deleteIndex>=0){
            this.ds_save_zar_baiguullaga_ajil_bair_zar.splice(deleteIndex, 1);
            this.$store.commit("removeFromZar",  id);
          }
        });
      },
      deleteBrigadData(id){
        this.$apollo.mutate({mutation: POST_DELETE_BRIGAD_AJIL_BAIR_ZAR,
          variables: {
            id: id,
          }
        }).then(() =>{
          this.$Notice.success({title: this.$t("successfully_Deleted"),});
          let deleteIndex = this.ds_save_zar_brigad_ajil_bair_zar.findIndex(item=>item.id == id);
          if(deleteIndex>=0){
            this.ds_save_zar_brigad_ajil_bair_zar.splice(deleteIndex, 1);
            this.$store.commit("removeBrigadAjiliinBairZar",  id);
          }
        });
      }
    },
    mounted() {
      this.getGET_SAVE_BAIGUULLAGA_AJIL_BAIR_ZAR();
      this.getGET_SAVE_BRIGAD_AJIL_BAIR_ZAR();
    }

}
</script>

<style scoped>

</style>
